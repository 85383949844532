<template>
    <div class="znzbfx g-glossy">
      <div style="position: absolute;top:0;left: 0;right: 0;bottom: 0;filter: blur(2px)"></div>
        <div class="title ">
            <p>智能装备分析</p>
          <div class="tab flex_c_c">
            <div class="tab-item flex_c_c" :class="{'tab-item-s':tabActive===1}" @click="showTabActive(1)">无人机</div>
            <div class="tab-item flex_c_c" :class="{'tab-item-s':tabActive===2}" @click="showTabActive(2)">机器人</div>
          </div>
        </div>
        <div class="znzbfx-content flex_b_c">
            <div class="znzbfx-echart-box">
<!--                <div class="znzbfx-echart" ref="znzbfxChart"></div>-->
              <a-progress class="znzbfx-progress"  :percent="curData.percent" :width="69" :stroke-color="'#67C23A'" type="circle" >
                <template #format="percent">
                  <span style="color: #67C23A">{{ percent }}%</span>
                  <br>
                  <span style="color: #707070;font-size: 12px">活跃率</span>
                </template>
              </a-progress>
<!--              <a-tooltip title="3 done / 3 in progress / 4 to do">-->
<!--              </a-tooltip>-->
            </div>
            <div class="znzbfx-data-text flex_b_c">
                <div class="znzbfx-left">
                    <p>累计注册量</p> 
                    <p style="top:63px">活跃量</p> 
                </div>
                <div class="znzbfx-right">
                    <div class="znzbfx-right-text blue flex_s_c">
                        <p><span>{{curData.value1}}</span>个</p>
                    </div>
                    <div class="znzbfx-right-text green flex_s_c">
                        <p><span>{{curData.value2}}</span>个</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  name:'znzbfx',
    data() {
        return {
          curData:{
            percent:80,
            value1:120,
            value2:150,
          },
          dataList:{
            1:{
              percent:80,
              value1:120,
              value2:150,
            },
            2:{
              percent:90,
              value1:135,
              value2:150,
            }
          },
            options: {
                xAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999'
                    }
                    
                },
                yAxis: {
                    type: 'category',
                    data: ['待执行', '执行中', '已执行', '逾期未完成']
                },
                series: [
                    {
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {
                            
                        },
                        emphasis: {
                            itemStyle: {
                            
                            }
                        },
                    }
                ]
            },
            tabActive: 1,
        }
    },
    mounted() {
        setTimeout(() => {
            // this.loadChart()
        }, 100);
    },
    methods:{
        loadChart(options) {
            if (options) {
                this.options = Object.assign(this.options, options)
            }
            let {znzbfxChart} = this.$refs
            let chart = this.$echarts.init(znzbfxChart)
            let data = [220, 182, 191, 234];
            let dataAxis = ['待执行', '执行中', '已执行', '逾期未完成'];
            chart.setOption({
                xAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999'
                    }
                    
                },
                yAxis: {
                    data: dataAxis,
                    axisLabel: {
                        inside: true,
                        color: '#fff'
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                },
                series: [
                    {
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#188df0' },
                                { offset: 1, color: '#188df0' }
                            ])
                        },
                        emphasis: {
                            itemStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#2378f7' },
                                { offset: 0.7, color: '#2378f7' },
                                { offset: 1, color: '#83bff6' }
                            ])
                            }
                        },
                        data: data
                    }
                ]
            })
        },
      showTabActive(value){
          this.tabActive = value;
          this.curData = this.dataList[value];
      }
    }
}
</script>
<style lang="scss" scoped>
.znzbfx{
  //position: absolute;
  font-size: 14px;
  margin-bottom: 10px;
  width: 288px;
  height: 178px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px 5px 8px 8px;
  box-shadow: 0 0 3px 3px rgba(33, 33, 33, 0.1);
  top: 600px;
    &.g-glossy::before{
      width: 288px;
      height: 178px;
    }
    .title{
        position: relative;
        padding:  0 5px 0 20px;
        height: 38px;
        font-size: 14px;
        color: #424242;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(180deg, rgba(83,213,191,0.25) 0%, rgba(0,132,201,0) 100%);
        border-radius: 5px 5px 0 0;
        &::before{
            position: absolute;
            left: 10px;
            content: "";
            width: 4px;
            height: 16px;
            background: url('@/assets/images/comp-title-before.png') no-repeat center;
            background-size: 100% 100%;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 269px;
            height: 1px;
            background: url('@/assets/images/comp-title-tab-line.png') no-repeat center;
            background-size: 100% 100%;
        }
        .paidan{
            color: #1DAAAA;
            width: 107px;
            height: 29px;
            background: url('@/assets/images/xsgk-paidan-btn.png') no-repeat center;
            background-size: 100% 100%;
            p{
                margin-top: -3px;
            }
            &::before{
                margin-top: -2px;
                margin-right: 7px;
                content: "";
                width: 17px;
                height: 15px;
                background: url('@/assets/images/icon-aeroplane.png') no-repeat center;
                background-size: 100% 100%;
            }
        }

      .tab{
        font-family: Microsoft YaHei, 微软雅黑;
        font-weight: 400;
        font-size: 12px;
        line-height: 0;
        color: #707070;
        width: 136px;
        height: 28px;
        background: url('@/assets/images/comp-title-tab-bg.png') no-repeat center;
        background-size: 100% 100%;
        .tab-item{
          width: 48%;
          margin-top: -2px;
          height: 21px;
          color: #707070;
        }
        .tab-item-s{
          color: #fff;
          background: url('@/assets/images/comp-title-tab-s.png') no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
    .znzbfx-content{
        margin: 0 auto;
        position: relative;
        width: 270px;
        height: 135px;
        .znzbfx-left{
            flex-shrink: 0;
            position: relative;
            width: 39px;
            height: 99px;
            background: url('@/assets/images/znzbfx-text-bg.png') no-repeat center;
            background-size: 100% 100%;
            margin-right: 46px;
            margin-top: -2px;
            p{
                position: absolute;
                top: 14px;
                left: 11px;
                white-space: nowrap;
            }
        }
        .znzbfx-right{
            .znzbfx-right-text{
                margin-bottom: 6px;
                width: 90px;
                height: 43px;
                background: url('@/assets/images/znzbfx-num-bg.png') no-repeat center;
                background-size: 100% 100%;
                span{
                    font-size: 18px;
                  line-height: 43px;
                    margin-right: 2px;
                }
                p{
                  margin-bottom: 0;
                }

                &.blue{
                    color: #2EB1B1;
                }
                &.green{
                    color: #67C23A;
                }
            }
        }
    }
    .znzbfx-echart-box{
        width: 88px;
        height: 80px;
        background: url('@/assets/images/znzbfx-charts.png') no-repeat center;
        background-size: 100% 100%;
    }
    .znzbfx-progress{
      margin: 5px auto;
    }
    .znzbfx-echart{
        width: 100%;
        height: 100%;
    }
}
</style>