<template>
    <div class="xszyfx g-glossy">
        <div class="title ">
            <p>巡视作业分析</p>
            <div class="tab flex_c_c">
                <div class="tab-item flex_c_c" :class="{'tab-item-s':tabActive===1}" @click="showTabActive(1)">发现缺陷</div>
                <div class="tab-item flex_c_c" :class="{'tab-item-s':tabActive===2}" @click="showTabActive(2)">智能作业</div>
            </div>
        </div>
        <div class="xszyfx-content">
            <div class="xszyfx-option-box flex_b_c" >
                <div class="xszyfx-option  flex_c_c"  v-for="(item,key) in tab2Data " @click="showTab2Active(key)" :class="{'xszyfx-option-s':tab2Active===key}">
                    <p>{{item.name}}</p>
                </div>

            </div>
            <div class="xszyfx-fxqx" v-show="tabActive===1">
                <div class="xszyfx-fxqx-data flex_b_c">
                    <div class="xszyfx-fxqx-data-text flex_s_c">
                        <p>发现缺陷</p>
                        <span>100</span>
                    </div>
                    <div class="xszyfx-fxqx-data-text flex_s_c">
                        <p>其中消除</p>
                        <span>50</span>
                    </div>
                </div>
                <div class="xszyfx-chart-box flex_b_c">
                    <div class="xszyfx-chart-item">
                      <a-progress class="xszyfx-chart-item-progress"  trail-color="#92DBCF" stroke-color="#E6A23C" :width="88" :percent="100"  :gap-degree="170" type="dashboard" >
                        <template #format="percent">
                          <span style="color: red"></span>
                        </template>
                      </a-progress>
                      <div class="xszyfx-chart-icon xszyfx-chart-icon1"></div>
                      <div class="xszyfx-chart-value">{{100}}%</div>
                      <div class="xszyfx-chart-name">发现率</div>
                    </div>
                    <div class="xszyfx-chart-item">
<!--                      -->
                      <a-progress class="xszyfx-chart-item-progress"  trail-color="#92DBCF" stroke-color="#E6A23C" :width="88" :percent="60"  :gap-degree="170" type="dashboard" >
                        <template #format="percent">
                          <span style="color: red"></span>
                        </template>
                      </a-progress>
                      <div class="xszyfx-chart-icon xszyfx-chart-icon2"></div>
                      <div class="xszyfx-chart-value">{{60}}%</div>
                      <div class="xszyfx-chart-name">消除率</div>
                    </div>
                </div>
            </div>
            <div class="xszyfx-znzy" v-show="tabActive===2">
                <div class="xszyfx-znzy-top flex_b_c">
                    <div class="xszyfx-znxss-box flex_c_c">
                        <div class="xszyfx-znxss-title">智能巡视数</div>
                        <p><span>500</span>个</p>
                    </div>
                    <div class="xszyfx-znxsl-box flex_c_c">
                        <div class="xszyfx-znxsl-title">智能巡视率</div>
                        <p><span>500</span>%</p>
                    </div>
                    <div class="xszyfx-znxsl-icon flex_c_c"></div>
                </div>
                <div class="xszyfx-znzy-conetnt flex_b_c">
                    <XszyfxItem v-for="item in 3" :key="item" :type="item"></XszyfxItem>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import XszyfxItem from '@/components/xszyfxItem.vue'
export default {
    name:'xszyfx',
    components:{
        XszyfxItem
    },
    data() {
        return {
          tab2Data:[
            {name:'全部',type:0},
            {name:'人工',type:1},
            {name:'无人机',type:2},
            {name:'机器人',type:3}
          ],
            options: {
                xAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999'
                    }
                    
                },
                yAxis: {
                    type: 'category',
                    data: ['待执行', '执行中', '已执行', '逾期未完成']
                },
                series: [
                    {
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {
                            
                        },
                        emphasis: {
                            itemStyle: {
                            
                            }
                        },
                    }
                ]
            },
            tabActive: 1,
          tab2Active: 1,
        }
    },
    mounted() {
        // setTimeout(() => {
        //     this.loadChart()
        // }, 100);
    },
    methods:{
        loadChart(options) {
            if (options) {
                this.options = Object.assign(this.options, options)
            }
            let {xszyfxChart} = this.$refs
            let chart = this.$echarts.init(xszyfxChart)
            let data = [220, 182, 191, 234];
            let dataAxis = ['待执行', '执行中', '已执行', '逾期未完成'];
            chart.setOption({
                xAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999'
                    }
                    
                },
                yAxis: {
                    data: dataAxis,
                    axisLabel: {
                        inside: true,
                        color: '#fff'
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                },
                series: [
                    {
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#188df0' },
                                { offset: 1, color: '#188df0' }
                            ])
                        },
                        emphasis: {
                            itemStyle: {
                            color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#2378f7' },
                                { offset: 0.7, color: '#2378f7' },
                                { offset: 1, color: '#83bff6' }
                            ])
                            }
                        },
                        data: data
                    }
                ]
            })
        },
      showTabActive(value){
        this.tabActive = value;
        // this.loadChart(this.tabDataList[value]);
      },
      showTab2Active(value){
        this.tab2Active = value;
        // this.loadChart(this.tabDataList[value]);
      }
    }
}
</script>
<style lang="scss" scoped>
.xszyfx{
    font-size: 14px;
    margin-bottom: 12px;
    width: 288px;
    height: 400px;
    background: rgba(255,255,255,0.1);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);
    border-radius: 5px 5px 8px 8px;
    color: #707070;
    &.g-glossy::before{
      width: 288px;
      height: 178px;
    }
    .title{
        position: relative;
        padding:  0 5px 0 20px;
        height: 38px;
        font-size: 14px;
        color: #424242;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(180deg, rgba(83,213,191,0.25) 0%, rgba(0,132,201,0) 100%);
        border-radius: 5px 5px 0 0;
        &::before{
            position: absolute;
            left: 10px;
            content: "";
            width: 4px;
            height: 16px;
            background: url('@/assets/images/comp-title-before.png') no-repeat center;
            background-size: 100% 100%;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 269px;
            height: 1px;
            background: url('@/assets/images/comp-title-tab-line.png') no-repeat center;
            background-size: 100% 100%;
        }
        .tab{
            font-family: Microsoft YaHei, 微软雅黑;
            font-weight: 400;
            font-size: 12px;
            line-height: 0;
            color: #707070;
            width: 136px;
            height: 28px;
            background: url('@/assets/images/comp-title-tab-bg.png') no-repeat center;
            background-size: 100% 100%;
            .tab-item{
                width: 48%;
                //margin-top: -3px;
                height: 21px;
                color: #707070;
            }
            .tab-item-s{
                color: #fff;
                background: url('@/assets/images/comp-title-tab-s.png') no-repeat center;
                background-size: 100% 100%;
            }
        }
        
    }
    .xszyfx-content{
        margin: 0 auto;
        position: relative;
        width: 270px;
        height: 100px;
       .xszyfx-option-box{
            .xszyfx-option{
                margin: 10px auto 12px;
                width: 25%;
                font-size: 14px;
                color: #707070;
                &.xszyfx-option-s{
                    &::before{
                        background: url('@/assets/images/option-s.png') no-repeat center;
                        background-size:  100% 100%;
                    }
                }
                &::before{
                    margin-right: 7px;
                    width: 12px;
                    height: 12px;
                    content: "";
                    background: url('@/assets/images/option.png') no-repeat center;
                    background-size:  100% 100%;
                }
            }
       }
       .xszyfx-fxqx{
            .xszyfx-fxqx-data-text{
                padding-left: 32px;
                width: 129px;
                height: 27px;
                background: url('@/assets/images/xszyfx-text-bg.png') no-repeat center;
                background-size:  100% 100%;
                span{
                    width: 40px;
                    color: #3FB5B7;
                    text-align: center;
                    font-size: 16px;
                }
            }
            .xszyfx-chart-box{
                margin: 11px auto 10px;

                .xszyfx-chart-item{
                    position: relative;
                    width: 129px;
                    &::before{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        width: 129px;
                        height: 65px;
                        background: url('@/assets/images/xszyfx-chart-bg-1.png') no-repeat center;
                        background-size:  100% 100%;
                    }
                    &::after{
                        position: absolute;
                        top: 97px;
                        left: 0;
                        content: "";
                        width: 129px;
                        height: 16px;
                        background: url('@/assets/images/xszyfx-chart-bg-2.png') no-repeat center;
                        background-size:  100% 100%;
                    }
                  .xszyfx-chart-item-progress{
                    position: absolute;
                    left: 20px;
                    top: 15px;
                  }
                  .xszyfx-chart-icon{
                    position: absolute;

                    top: 35px;
                    &.xszyfx-chart-icon1{
                      width: 22px;
                      height: 20px;
                      left: 54px;
                      background: url('@/assets/images/xszyfx-fxl-icon.png') no-repeat center;
                    }
                    &.xszyfx-chart-icon2{
                      width: 16px;
                      height: 17px;
                      left: 58px;
                      background: url('@/assets/images/xszyfx-xcl-icon.png') no-repeat center;
                    }
                  }
                  .xszyfx-chart-value{
                    position: absolute;
                    left: 15px;
                    top: 75px;
                    width: 100px;
                    height: 20px;
                    text-align: center;
                    font-size: 18px;
                    font-family: Arial, Arial;
                    font-weight: bold;
                    color: #E6A23C;
                    line-height: 0px;
                  }
                  .xszyfx-chart-name{
                    position: absolute;
                    left: 45px;
                    top: 95px;
                    width: 39px;
                    height: 17px;
                    font-size: 13px;
                    font-family: Microsoft YaHei, 微软雅黑;
                    font-weight: 400;
                    color: #000000;
                    line-height: 12px;
                  }
                }
            }
       }
       .xszyfx-znzy{
            position: relative;
            .xszyfx-znzy-top{
                margin-bottom: 40px;
            }
            .xszyfx-znxss-box{
                padding-left: 46px;
                position: relative;
                width: 80px;
                height: 31px;
                background: url('@/assets/images/xszyfx-znxss.png') no-repeat center;
                background-size: 100% 100%;
                flex-direction: column;
                &::before{
                    position: absolute;
                    top: 2px;
                    left: 5px;
                    content: "";
                    width: 20px;
                    height: 22px;
                    background: url('@/assets/images/xszyfx-znxss-icon.png') no-repeat center;
                    background-size: 100% 100%;
                }
                .xszyfx-znxss-title{
                    margin-top: 10px;
                    margin-bottom: 3px;
                    font-size: 10px;
                    width: 80px;
                    text-align: left;
                }
                p{
                    text-align: left;
                    width: 80px;
                    font-size: 10px;
                }
                span{
                    font-size: 14px;
                    color: #2FB5B5;
                }
            }
            .xszyfx-znxsl-box{
                padding-right: 46px;
                position: relative;
                width: 80px;
                height: 31px;
                background: url('@/assets/images/xszyfx-znxsl.png') no-repeat center;
                background-size: 100% 100%;
                flex-direction: column;
                &::before{
                    position: absolute;
                    top: 0px;
                    right: 5px;
                    content: "";
                    width: 17px;
                    height: 17px;
                    background: url('@/assets/images/xszyfx-znxsl-icon.png') no-repeat center;
                    background-size: 100% 100%;
                }
                .xszyfx-znxsl-title{
                    margin-top: 10px;
                    margin-bottom: 3px;
                    font-size: 10px;
                    width: 80px;
                    text-align: right;
                }
                p{
                    font-size: 10px;
                    width: 80px;
                    text-align: right;
                }
                span{
                    margin-right: 3px;
                    font-size: 14px;
                    color: #67C23A;
                }
            }
            .xszyfx-znxsl-icon{
                position: absolute;
                left: 50%;
                top: 20px;
                transform: translateX(-50%);
                width: 99px;
                height: 29px;
                background: url('@/assets/images/xszyfx-znzy.png') no-repeat center;
                background-size: 100% 100%;
                &::before{
                    content: "";
                    width: 34px;
                    height: 42px;
                    background: url('@/assets/images/xszyfx-znzy-icon.png') no-repeat center;
                    background-size: 100% 100%;
                }
            }
            .xszyfx-znzy-conetnt{
                
            }
       }
    }
    // .xszyfx-echart{
    //     width: 100%;
    //     height: 100%;
    // }
}
</style>