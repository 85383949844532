<template>
    <div class="xsqd">
        <div class="title">
            <p>巡视清单</p>
            <div class="tab flex_c_c">
                <div class="tab-item flex_c_c tab-item-s">巡视清单</div>
                <div class="tab-item flex_c_c">单位对比</div>
            </div>
        </div>
        <div class="search">
            <input placeholder="请输入"/>
        </div>
        <div class="option-title">计划状态</div>
        <div class="option-box flex_s_c">
            <div class="option-item option-item-s flex_c_c">全部</div>
            <div class="option-item option-item-1 flex_c_c">完成</div>
            <div class="option-item option-item-2 flex_c_c">未完成</div>
        </div>
        <div class="option-title">巡视方式</div>
        <div class="option-box flex_s_c">
            <div class="option-item option-item-s flex_c_c">全部</div>
            <div class="option-item option-item-3 flex_c_c">人工巡视</div>
            <div class="option-item option-item-4 flex_c_c">无人机巡视</div>
            <div class="option-item option-item-4 flex_c_c">机器人巡视</div>
        </div>
        <div class="search-result flex_b_c">
            <p>搜索结果<span>934</span>条</p>
            <i></i>
        </div>
        <div class="list">
            
            <xsqdItem v-for="(item,index) in list" :key="'xsqdItem'+index"></xsqdItem>
            
        </div>
    </div>
</template>
<script>
import xsqdItem from '@/components/xsqdItem.vue'
export default {
  name: 'xsqd',
  components: {
    xsqdItem
  },
  data() {
    return {
        
        list:[
            {},
            {},
            {},
            {},
        ]
    }
  },
}
</script>
<style lang="scss" scoped>
.xsqd{
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    width: 300px;
    height: 944px;
    background: url('@/assets/images/xsqd-bg.png') no-repeat center;
    background-size: 100% 100%;
    .title{
        position: relative;
        padding:  0 10px 0 26px;
        height: 38px;
        font-size: 14px;
        color: #424242;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &::before{
            position: absolute;
            left: 15px;
            content: "";
            width: 4px;
            height: 16px;
            background: url('@/assets/images/comp-title-before.png') no-repeat center;
            background-size: 100% 100%;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 15px;
            content: "";
            width: 269px;
            height: 1px;
            background: url('@/assets/images/comp-title-tab-line.png') no-repeat center;
            background-size: 100% 100%;
        }
        .tab{
            font-size: 12px;
            color: #707070;
            width: 136px;
            height: 28px;
            background: url('@/assets/images/comp-title-tab-bg.png') no-repeat center;
            background-size: 100% 100%;
            .tab-item{
                width: 48%;
                margin-top: -3px;
                height: 21px;
            }
            .tab-item-s{
                color: #fff;
                background: url('@/assets/images/comp-title-tab-s.png') no-repeat center;
                background-size: 100% 100%;
            }
        }
    }
    .search{
        margin: 8px auto 0;
        width: 282px;
        height: 41px;
        background: url('@/assets/images/xsqd-search.png') no-repeat center;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        input{
            margin-top: -2px;
            margin-left: 15px;
            font-size: 14px;
            height: 25px;
            color: #707070;
            background-color: none;
            outline: none;
        }
    }
    .option-title{
        width: 270px;
        margin: 8px auto 12px;
        text-align: left;
        font-size: 14px;
        color: #1DAAAA;
    }
    .option-box{
        width: 270px;
        margin: 0 auto;
        .option-item{
            margin-right: 2px;
            height: 26px;
            font-size: 14px;
            color: #707070;
            &.option-item-1{
                width: 43px;
                background: url('@/assets/images/option-bg-1.png') no-repeat center;
                background-size: 100% 100%;
            }
            &.option-item-2{
                width: 58px;
                background: url('@/assets/images/option-bg-2.png') no-repeat center;
                background-size: 100% 100%;
            }
            &.option-item-3{
                width: 66px;
                background: url('@/assets/images/option-bg-3.png') no-repeat center;
                background-size: 100% 100%;
            }
            &.option-item-4{
                width: 78px;
                background: url('@/assets/images/option-bg-4.png') no-repeat center;
                background-size: 100% 100%;
            }
            &.option-item-s{
                color: #1DAAAA;
                width: 49px;
                background: url('@/assets/images/option-bg-s.png') no-repeat center;
                background-size: 100% 100%;
            }
        }
    }
    .search-result{
        width: 270px;
        margin: 12px auto 8px;
        font-size: 14px;
        color: #424242;
        span{
            color: #1DAAAA;
            margin: 0 9px;
        }
        i{
            width:  11px;
            height:  10px;
            background: url('@/assets/images/arrow.png') no-repeat center;
            background-size: 100% 100%;
        }
    }
    .list{
        flex: 1;
        margin: 0 auto 8px;
        width: 270px;
        overflow-x: hidden;
        overflow-y: scroll;
       
    }
}
</style>