<template>
  <div class="mapComp" ref="map"></div>
</template>

<script>
import * as echarts from "echarts";
import ningbo from "../assets/geoJson/ningbo.json";
import ningbo1 from "../assets/geoJson/ningbo1.json";
export default {
  name: "mapComp",
  data() {
    return {
      chart: null,
      colors: [
        "rgba(255,255,255,0.7)",
        "rgba(255,255,255,0.7)",
        "rgba(173,255,255,0.7)",
        "rgba(114,203,206,0.7)",
      ],
      series: {
        name: "",
        type: "map",
        geoIndex: 0,
        zlevel: 1,
      },
      series1: {
        type: "map",
        map: "zhejiang",
        // aspectScale:1,
        geoIndex: 1,
        layoutCenter: ["53%", "60%"],
        layoutSize: "90%",
        zlevel: 0,
        silent: true,
        showLegendSymbol: false, // 存在legend时显示
        label: {
          normal: {
            show: false,
          },
          emphasis: {
            show: false,
            textStyle: {
              color: "#fff",
            },
          },
        },
        roam: false,
        itemStyle: {
          normal: {
            areaColor: "rgba(0, 0, 0, 0)",
            borderColor: "rgba(13, 205, 205, 1)",
            borderWidth: 3,
            shadowColor: "rgba(13, 205, 205, 1)",
            shadowBlur: 10,
          },
          areaColor: "#013C62",
          shadowColor: "#01273F",
          shadowOffsetX: 0,
          shadowOffsetY: 25,
        },
      },
      geo: {
        map: "zhejiang",
        roam: false,
        zlevel: 1,
        id: "xzmap",
        // aspectScale: 1.003,
        // top:10,
        // bottom:10,
        // left:10,
        // right:10,
        // aspectScale:1,
        geoIndex: 1,
        layoutCenter: ["53%", "60%"],
        layoutSize: "90%",
        show: true,
        label: {
          normal: {
            show: true,
            fontWeight: "bold",
          },
          emphasis: {
            show: true,
            fontWeight: "bold",
          },
        },
        itemStyle: {
          normal: {
            borderColor: "rgba(13, 205, 205, 1)",
            borderWidth: 2,
            // areaColor: "rgba(100,100,200,0.1)",
          },
          emphasis: {
            // color: overColor,
            // shadowOffsetX: 0,
            // shadowOffsetY: 0,
            // shadowBlur: 20,
            borderWidth: 1,
            offsetY: 1,
            // shadowColor: 'rgba(255, 255, 255, 1)'
          },
        },
        smooth: true,
        type: "line",
        scaleLimit: {
          min: 1,
          max: 5,
        },
      },
      option: {
        backgroundColor: "rgba(128, 128, 128, 0)",
        title: {
          text: "",
          subtext: "",
          sublink: "",
          textStyle: {
            color: "#eee",
          },
        },
      },
    };
  },
  components: {},
  mounted() {
    setTimeout(() => {
      this.loadChart("ningbo", ningbo);
    }, 100);
  },
  methods: {
    loadChart(mName, Mjson) {
      let { map } = this.$refs;
      let chart = (this.chart = echarts.init(map));
      this.option.series = [this.series, this.series1];
      this.option.geo = this.geo;
      echarts.registerMap(mName, Mjson);
      echarts.registerMap("ningbo1", ningbo1);
      this.option.geo.map = mName;
      this.geo.regions = this.getRegions(Mjson.features);
      this.series1.map = "ningbo1";
      chart.setOption(this.option, {
        notMerge: true,
        lazyUpdate: true,
        silent: true,
      });
      let nameList = [];
      let features = Mjson.features;
      for (let i = 0; i < features.length; i++) {
        let o = features[i].properties;
        nameList.push(o.name);
      }
      setTimeout(this.renderEachCity, 50, nameList, features);
    },

    getRegions(features) {
      let arr = [];
      features.forEach((element) => {
        let color = this.colors[(this.colors.length * Math.random()) >> 0];
        let obj = {
          name: element.properties.name,
          itemStyle: { normal: { color: color }, emphasis: { color: color } },
        };
        arr.push(obj);
      });

      return arr;
    },
    getCenter(cname, features) {
      for (let i = 0; i < features.length; i++) {
        let o = features[i].properties;
        if (o.name == cname) {
          return o.centroid || o.center;
        }
      }
      return null;
    },
    getZhuLineColor(color) {
      let c = {
        type: "linear",
        x0: 0,
        y0: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: color, // 0% 处的颜色
          },
          {
            offset: 0.8,
            color: "rgba(192,183,118,1)", // 100% 处的颜色
          },
          {
            offset: 1,
            color: "#ffffff", // 100% 处的颜色
          },
        ],
        global: false, // 缺省为 false
      };
      return c;
    },
    renderEachCity(rawData, features) {
      let option = {
        xAxis: [],
        yAxis: [],
        grid: [],
        series: [],
      };

      // let inflationStartIdx = 14;
      // let inflationYearCount = 3;
      // let inflationYearStart = '2006';
      // let xAxisCategory = [];
      // for (let i = 0; i < inflationYearCount; i++) {
      //     xAxisCategory.push((+inflationYearStart + i) + '');
      // }
      let self = this;
      let maxNum = 0;
      echarts.util.each(rawData, function (cname, idx) {
        let geoCoord = self.getCenter(cname, features);
        if (!geoCoord) {
          return;
        }
        let coord = self.chart.convertToPixel({ geoId: "xzmap" }, geoCoord);
        // let boundL = -12.782788213627585;
        // let boundR = 35.92763028872384;
        // let boundT = 32.22854555899493;
        // let boundB = 95.18817097360194;
        // if (!coord ||
        //     geoCoord[0] < boundL ||
        //     geoCoord[0] > boundR ||
        //     geoCoord[1] > boundB ||
        //     geoCoord[1] < boundT
        // ) {
        //     return;
        // }
        console.log(cname, coord, geoCoord, rawData);
        idx += "";
        let inflationData = [(Math.random() * 100) >> 0];
        let colorList = [
          "rgba(221, 94, 31, 1)",
          "rgba(13, 205, 205, 1)",
          "rgba(13, 205, 205, 1)",
          "rgba(13, 205, 205, 1)",
        ];
        let lebelData = [];
        maxNum = 1;
        let ww = 20;
        // let dwdm = getDwdmByMapName(cname);
        // let dataItems = currentMapZhuData[dwdm];
        // if (!dataItems) {
        //   return;
        // }
        // let ww = 0;
        // for (let i = 0; i < dataItems.length; i++) {
        //   let obj = dataItems[i];
        //   inflationData.push(obj.value);
        //   colorList.push(obj.color);
        //   lebelData.push("");
        //   maxNum = Math.max(obj.value, maxNum);
        // }
        // ww = dataItems.length * 30;
        // for (let k = 0; k < inflationYearCount; k++) {
        //     inflationData.push(dataItem[inflationStartIdx + k]);
        // }

        option.xAxis.push({
          id: idx,
          gridId: idx,
          type: "category",
          name: "",
          // nameStyle: {
          //     color: 'red',
          //     fontSize: 28
          // },
          nameLocation: "middle",
          nameGap: 2,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
            onZero: false,
            lineStyle: {
              color: "#666",
            },
          },
          // data: xAxisCategory,
          data: lebelData,
          zlevel: 3,
          z: 100,
        });
        option.yAxis.push({
          id: idx,
          gridId: idx,
          min: 0,
          max: maxNum,
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
            lineStyle: {
              color: "#1C70B6",
            },
          },
          zlevel: 3,
          z: 100,
        });
        option.grid.push({
          id: idx,
          width: ww,
          height: 100,
          left: coord[0] - (ww >> 1),
          top: coord[1] - 118,
          zlevel: 3,
          z: 100,
        });
        option.series.push({
          id: idx,
          type: "bar",
          xAxisId: idx,
          yAxisId: idx,
          barGap: 4,
          barCategoryGap: 4,
          // data: inflationData,
          data: inflationData,
          z: 100,
          zlevel: 3,
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: "#000",
              fontSize: 20,
              font: "微软雅黑",
              fontWeight: 400,
            },
          },
          itemStyle: {
            normal: {
              borderRadius: [10, 10, 0, 0],
              color: function (params) {
                // 柱状图每根柱子颜色

                return self.getZhuLineColor(colorList[params.dataIndex]);
              },
            },
            emphasis: {
              show: false,
              borderRadius: [10, 10, 0, 0],
              color: function (params) {
                // return self.getZhuLineColor(colorList[0]);
                return "rgba(192,183,118,1)";
              },
            },
          },
        });
      });
      console.time("a");
      this.chart.setOption(option);
      console.timeEnd("a");
    },
  },
};
</script>
<style lang="scss" scoped>
.mapComp {
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  align-items: center;
}
</style>