<template>
  <div class="home">
    <div class="home-bg">
      <MapComp></MapComp>
    </div>
      <TitleComp></TitleComp>
      <div class="argument flex_c_c">
        <div class="position flex_b_c">
          <p>宁波市</p>
          <i class="position-logo"></i>
        </div>
        <div class="date-box flex_b_c">
          <p class="flex_s_c">
            2023-12-20
            <i class="date-logo"></i>
          </p>
          <p>至</p>
          <p class="flex_e_c">
            2023-12-20
            <i class="date-logo"></i>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="right-box">
          <div class="right-btn-box">
            <div class="right-btn-item flex_c_c"><i class="zuzhishu"></i>组织树</div>
            <div class="right-btn-item flex_c_c"><i class="tuceng"></i>图层</div>
          </div>
          <Xsqd></Xsqd>
          <div class="right-xsjh">
            <Xsjh></Xsjh>
          </div>
        </div>
      </div>
      <div class="left">
        <div class="left-box">
          <div class="left-content">
            <Xsjd></Xsjd>
            <Xsgk></Xsgk>
            <Xszyfx></Xszyfx>
            <Znzbfx></Znzbfx>
          </div>
        </div>
        <div class="left-tab">
          <div 
            class="left-tab-item flex_c_c" 
            v-for="(item,index) in leftTabList"
            :key="'leftTab'+index"
            :class="{'left-tab-item-s': leftTab==index}" @click="clickTab(index)"
          >
             {{item}}
          </div>
          
        </div>
        
        
      </div>
    <div class="left-box2">

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import TitleComp from '../components/title.vue'
import Xsqd from '../components/xsqd.vue'
import Xsjh from '../components/xsjh.vue'
import Xsjd from '../components/xsjd.vue'
import Xsgk from '../components/xsgk.vue'
import Znzbfx from '../components/znzbfx.vue'
import Xszyfx from '../components/xszyfx.vue'
import MapComp from '../components/map.vue'
import Footer from '../components/footer.vue'

export default {
  name: 'HomePage',
  components: {
    TitleComp,
    Xsqd,
    Xsjh,
    Xsjd,
    Znzbfx,
    Xsgk,
    Xszyfx,
    MapComp,
    Footer
  },
  data(){
    return {
      leftTabList:[
        "巡视","检测","缺陷","检修"
      ],
      leftTab: 0
    }
  },
  methods:{
    clickTab(index){
      this.leftTab = index
    }
  }
}
</script>
<style lang="scss">
.home{
  .home-bg{
    position: absolute;
    z-index: -1000;
    width: 1920px;
    min-height: 1000px;
    height: 100%;
     background: url("@/assets/images/ditu1.png") top left;
  }
  .argument{
    position: absolute;
    left: 400px;
    top: 70px;
    font-size: 14px;
    .position{
      position: relative;
      padding-left: 13px;
      padding-right: 14px;
      margin-right: 13px;
      width: 214px;
      height: 35px;
      background: url("@/assets/images/position.png") top left;
      background-size: 100% 100%;
      color: #1DAAAA;
      i.position-logo{
        width: 16px;
        height: 19px;
        background: url("@/assets/images/position-icon.png") top left;
        background-size: 100% 100%;
        &::before{
          position: absolute;
          content: "";
          top: 50%;
          transform: translateY(-50%);
          right: 42px;
          width: 0px;
          height: 16px;
          opacity: 1;
          border: 1px solid #C8CECE;
        }
      }
    }
    .date-box{
      padding: 0 15px;
      width: 263px;
      height: 35px;
      background: url("@/assets/images/date-bg.png") top left;
      background-size: 100% 100%;
      i.date-logo{
        margin-left: 10px;
        width: 15px;
        height: 16px;
        background: url("@/assets/images/date-icon.png") top left;
        background-size: 100% 100%;
      }
    }
  }
  .right{
    position: absolute;
    right: 0;
    top: 100px;
    .right-box{
      position: relative;
      .right-btn-box{
        position: absolute;
        left: -240px;
        display: flex;
        .right-btn-item{
          margin-right: 8px;
          width: 99px;
          height: 28px;
          background: #FFFFFF;
          box-shadow: 0px 1px 2px 1px rgba(146,146,146,0.4), inset 0px 2px 5px 1px rgba(147,206,188,0.4);
          border-radius: 40px 40px 40px 40px;
          opacity: 1;
          border: 1px solid #FFFFFF;
          color: #1DAAAA;
          font-size: 14px;
          i.tuceng{
            margin-right: 12px;
            width: 17px;
            height: 16px;
            background: url('@/assets/images/tuceng.png') no-repeat center;
            background-size: 100% 100%;
          }
          i.zuzhishu{
            margin-right: 9px;
            width: 15px;
            height: 14px;
            background: url('@/assets/images/zuzhishu.png') no-repeat center;
            background-size: 100% 100%;
          }
        }
      }
    }
    .right-xsjh{
      position: absolute;
      left: -322px;
      top: 37px;
    }

  }
  .left{
    position: absolute;
    left: 0;
    width: 36px;
    height: 976px;
    background: url('@/assets/images/left-bg.png') no-repeat center;
    background-size:  100% 100%;
    .left-tab{
      padding-top: 20px;
      font-size: 14px;
      color: #707070;
      .left-tab-item{
        writing-mode: vertical-rl;
        width: 37px;
        height: 145px;
        background: url('@/assets/images/left-tab-2.png') no-repeat center;
        background-size:  100% 100%;
        margin-bottom: -15px;
        &.left-tab-item-s{
          color: #1DAAAA;
          margin-left: -9px;
          width: 54px;
          height: 175px;
          background: url('@/assets/images/left-tab-s-2.png') no-repeat center;
          background-size:  100% 100%;
          margin-top: -20px;
          margin-bottom: -20px;
        }
      }
    }
    .left-box{
      position: relative;
      .left-content{
        position: absolute;
        left: 50px;
        top: 16px;
      }
    }
  }
  .left-box2{
    position: absolute;
    left: 0;
    top: 0;
  }
  .g-glossy{
    backdrop-filter:blur(5px);

  }

  //.g-glossy::before{
  //  content: "";
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  right: 0;
  //  bottom: 0;
  //  background-image: url("@/assets/images/ditu1.png");
  //  background-repeat: no-repeat;
  //  background-attachment: fixed;
  //  //background-size: cover;
  //  filter: blur(5px);
  //  z-index: -1;
  //}
}
</style>
