<template>
    <div class="xsgk g-glossy">

        <div class="title ">
            <p>巡视概况</p>
            <div class="paidan flex_c_c "><p>一键派单</p></div>
        </div>
        <div class="xsgk-content flex_s_c">
            <div class="xsgk-tab">
                <div class="xsgk-tab-item flex_c_c" :class="{'xsgk-tab-item-s':tabActive===1}" @click="tabActive=1">巡视方式</div>
                <div class="xsgk-tab-item flex_c_c" :class="{'xsgk-tab-item-s':tabActive===2}"  @click="tabActive=2">巡视类型</div>
            </div>
            <div class="xsgk-right ">
                <div class="xsgk-right-top flex_c_c">
                    <p>执行率</p>
                    <div class="xsgk-progress">
                        <div class="xsgk-progress-value"></div>
                        <div class="xsgk-progress-popover flex_c_c xsgk-progress-popover-1">
                            计划<span class="cyan">100</span>
                        </div>
                        <div class="xsgk-progress-popover flex_c_c xsgk-progress-popover-2">
                            完成<span class="green">58</span>
                        </div>
                        <div class="xsgk-progress-popover flex_c_c xsgk-progress-popover-3">
                            在途<span class="blue">58</span>
                        </div>
                    </div>
                    <p class="num">80</p>
                    <p class="unit">80</p>
                </div>
                <div class="xsgk-jqrxs ">
                    <div class="xsgk-jqrxs-title flex_s_c">机器人巡视</div>
                    <div class="xsgk-jqrxs-data flex_s_c">
                        <span class="cyan">37</span>
                        <div class="arrow-up">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10">
                                <text id="个" transform="translate(1 7)" fill="#707070" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" font-size="6" font-family="MicrosoftYaHei, Microsoft YaHei"><tspan x="0" y="0">个</tspan></text>
                            </svg>
                        </div>
                        <span class="cyan">30</span>
                        <div class="unit">%</div>
                    </div>
                </div>
                <div class="xsgk-rgxs ">
                    <div class="xsgk-rgxs-title flex_e_c">人工巡视</div>
                    <div class="xsgk-rgxs-data flex_e_c">
                        <span class="green1">37</span>
                        <div class="arrow-up">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10">
                                <text id="个" transform="translate(1 7)" fill="#707070" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" font-size="6" font-family="MicrosoftYaHei, Microsoft YaHei"><tspan x="0" y="0">个</tspan></text>
                            </svg>
                        </div>
                        <span class="green1">30</span>
                        <div class="unit">%</div>

                    </div>
                </div>
                <div class="xsgk-wrjxs ">
                    <div class="xsgk-wrjxs-title flex_e_c">无人机巡视</div>
                    <div class="xsgk-wrjxs-data flex_e_c">
                        <span class="red">37</span>
                        <div class="arrow-up">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 8 10">
                                <text id="个" transform="translate(1 7)" fill="#707070" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" font-size="6" font-family="MicrosoftYaHei, Microsoft YaHei"><tspan x="0" y="0">个</tspan></text>
                            </svg>
                        </div>
                        <span class="red">30</span>
                        <div class="unit">%</div>
                    </div>
                </div>
            </div>
             <div class="xsgk-echart-content">
               <div class="xsgk-echart-bg"></div>
               <div class="xsgk-echart" ref="xsgkChart"></div>
               <div class="xsgk-echart-icon"></div>
             </div>
        </div>
    </div>
</template>
<script>
export default {
   name:'xsgk',
    data() {
        return {
            options: {
                xAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999'
                    }
                    
                },
                yAxis: {
                    type: 'category',
                    data: ['待执行', '执行中', '已执行', '逾期未完成']
                },
                series: [
                    {
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {
                            
                        },
                        emphasis: {
                            itemStyle: {
                            
                            }
                        },
                    }
                ]
            },
            tabActive: 1,
        }
    },
    mounted() {
        setTimeout(() => {
            this.loadChart([40,30,30])
        }, 100);
    },
    methods:{
        loadChart(value) {

            let {xsgkChart} = this.$refs
            let chart = this.$echarts.init(xsgkChart)
         let option = {
           "animation": true,
           "series": [{
             "type": "pie",
             "center": ["50%", "50%"],
             "radius": ["50%", "58%"],
             itemStyle: {
               normal: {
                 shadowBlur: 10,
                 shadowColor: "#aec3cb"
               }
             },
             "color": ["#67C23A", "#EE6969", "#2FB5B5", "#ff924f", "#00FFA8", "#9F17FF", "#FFE400", "#FE2C8A"],
             "startAngle": 125,
             "label": {
               "normal": {
                 show: false
               }
             },
             "data": [{
               name: "人工巡视",
               value: value[0],

             }, {
               name: "无人机巡视",
               value: value[1],
             }, {
               name: "机器人巡视",
               value: value[2],
             }]
           }]
         };
            chart.setOption(option)
        },
    }
}
</script>
<style lang="scss" scoped>
.xsgk{
    //position: absolute;
    //left: 300px;
    font-size: 14px;
    margin-bottom: 12px;
    width: 288px;
    height: 178px;
    background: rgba(255,255,255,0.1);
    border-radius: 5px 5px 8px 8px;
    box-shadow: 0 0 3px 3px rgba(33, 33, 33, 0.1);
    color: #707070;
    &.g-glossy::before{
      width: 288px;
      height: 178px;
    }
    .title{
        position: relative;
        padding:  0 5px 0 20px;
        height: 38px;
        font-size: 14px;
        color: #424242;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: linear-gradient(180deg, rgba(83,213,191,0.25) 0%, rgba(0,132,201,0) 100%);
        border-radius: 5px 5px 0 0;
        &::before{
            position: absolute;
            left: 10px;
            content: "";
            width: 4px;
            height: 16px;
            background: url('@/assets/images/comp-title-before.png') no-repeat center;
            background-size: 100% 100%;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 269px;
            height: 1px;
            background: url('@/assets/images/comp-title-tab-line.png') no-repeat center;
            background-size: 100% 100%;
        }
        .paidan{
            color: #1DAAAA;
            width: 107px;
            height: 29px;
            background: url('@/assets/images/xsgk-paidan-btn.png') no-repeat center;
            background-size: 100% 100%;
            p{
                margin-top: -3px;
            }
            &::before{
                margin-top: -2px;
                margin-right: 7px;
                content: "";
                width: 17px;
                height: 15px;
                background: url('@/assets/images/icon-aeroplane.png') no-repeat center;
                background-size: 100% 100%;
            }
        }
        
    }
    .xsgk-content{
        position: relative;
        width: 280px;
        height: 135px;
        .xsgk-tab{
            margin-top: -8px;
            padding-left: 2px;
            margin-right: 10px;
            font-size: 10px;
            .xsgk-tab-item{
                writing-mode: vertical-rl;
                width: 19px;
                height: 73px;
                background: url('@/assets/images/xsgk-tab.png') no-repeat center;
                background-size:  100% 100%;
                margin-bottom: -15px;
                &.xsgk-tab-item-s{
                    margin-top: -3px;
                    margin-left: -3px;
                    color: #1DAAAA;
                    width: 26px;
                    height: 81px;
                    background: url('@/assets/images/xsgk-tab-s.png') no-repeat center;
                    background-size:  100% 100%;
                }
            }
        }
    }
    .xsgk-right{
        height: 75px;
        font-size: 9px;
        .xsgk-progress{
            margin-left: 13px;
            position: relative;
            width: 176px;
            height: 7px;
            background: linear-gradient(180deg, #0085C9 0%, rgba(0,133,201,0) 30%, rgba(0,133,201,0.5) 61%, #0085C9 100%);
            border-radius: 90px 90px 90px 90px;
            &::before{
                position: absolute;
                right: -1px;
                top: -1px;
                content: "";
                width: 9px;
                height: 9px;
                background: linear-gradient(180deg, #0085C9 0%, rgba(0,133,201,0) 30%, rgba(0,133,201,0.5) 61%, #0085C9 100%);
                border-radius: 100%;
            }
            .xsgk-progress-value{
                position: relative;
                width: 89px;
                height: 7px;
                background: linear-gradient(180deg, #6EE198 0%, #EBFFFA 30%, rgba(51,204,138,0.5) 61%, #33CC61 100%);
                border-radius: 90px 90px 90px 90px;
                &::before{
                    position: absolute;
                    right: -1px;
                    top: -1px;
                    content: "";
                    width: 9px;
                    height: 9px;
                    background: linear-gradient(180deg, #6EE198 0%, #EBFFFA 30%, rgba(51,204,138,0.5) 61%, #33CC61 100%);
                    border-radius: 100%;
                }
            }
            
        }
        p.unit{
            font-size: 6px;
        }
        p.num{
            margin-left: 3px !important;
            margin-right: 3px !important;
            font-size: 9px;
            color: #67C23A;
        }
        .xsgk-progress-popover{
            position: absolute;
            width: 50px;
            height: 16px;
            background: rgba($color: #fff, $alpha: .4);
            box-shadow: 0px 1px 2px 1px rgba(146,146,146,0.4), inset 0px 0px 6px 1px rgba(29,170,170,0.4);
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #FFFFFF;
            top: -25px;
            &.xsgk-progress-popover-1{
                left: 0px;
            }
            &.xsgk-progress-popover-2{
                left: 50%;
                transform: translateX(-50%);
            }
            &.xsgk-progress-popover-3{
                right: 0px;
                transform: translateX(50%);
            }
            span{
                font-size: 11px;
                width: 20px;
            }
        }
        span{
            &.cyan{
                color: #2FB5B5;
            }
            &.green{
                color: #33CC99;
            }
            &.green1{
                color: #67C23A;
            }
            &.blue{
                color: #0085C9;
            }
            &.red{
                color: #EE6969;
            }
        }
        .xsgk-jqrxs{
            position: absolute;
            top: 50px;
            width: 103px;
            .xsgk-jqrxs-title{
                position: relative;
                font-size: 8px;
                width: 100px;
                height: 30px;
                &::before{
                    margin-right: 8px;
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: url('@/assets/images/xsgk-jqr.png') no-repeat center;
                    background-size: 100% 100%;
                }
                &::after{
                    position: absolute;
                    content: "";
                    bottom: 0;
                    width: 103px;
                    height: 7px;
                    background: url('@/assets/images/xsgk-line.png') no-repeat center;
                    background-size: 100% 100%;
                }
            }
            .xsgk-jqrxs-data{
                margin-top: -7px;
                padding-left: 5px;
            }
            .arrow-up{
                margin-top: 4px;
                margin-left: 8px;
                margin-right: 10px;
            }
            .unit{
                font-size: 6px;
                margin-left: 6px;
            }
        }
        .xsgk-rgxs{
            position: absolute;
            top: 50px;
            right: 0;
            width: 103px;
            .unit{
                font-size: 6px;
                margin-left: 6px;
            }
            .xsgk-rgxs-title{
                position: relative;
                font-size: 8px;
                width: 100px;
                height: 30px;
                &::after{
                    margin-left: 8px;
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: url('@/assets/images/xsgk-rg.png') no-repeat center;
                    background-size: 100% 100%;
                }
                &::before{
                    position: absolute;
                    content: "";
                    bottom: 0;
                    width: 103px;
                    height: 7px;
                    background: url('@/assets/images/xsgk-line.png') no-repeat center;
                    background-size: 100% 100%;
                    transform: scaleX(-1);
                }
            }
            .arrow-up{
                margin-top: 4px;
                margin-right: 8px;
                margin-left: 10px;
            }
            .xsgk-rgxs-data{
                margin-top: -7px;
                padding-right: 5px;
            }
            
        }
        .xsgk-wrjxs{
            position: absolute;
            top: 90px;
            right: 0;
            width: 103px;
            .unit{
                font-size: 6px;
                margin-left: 6px;

            }
            .xsgk-wrjxs-title{
                position: relative;
                font-size: 8px;
                width: 100px;
                height: 30px;
                &::after{
                    margin-left: 8px;
                    content: "";
                    width: 2px;
                    height: 10px;
                    background: url('@/assets/images/xsgk-wrj.png') no-repeat center;
                    background-size: 100% 100%;
                }
                &::before{
                    position: absolute;
                    content: "";
                    bottom: 0;
                    width: 103px;
                    height: 7px;
                    background: url('@/assets/images/xsgk-line.png') no-repeat center;
                    background-size: 100% 100%;
                    transform: scaleX(-1);
                }
            }
            .arrow-up{
                margin-top: 4px;
                margin-right: 8px;
                margin-left: 10px;
            }
            .xsgk-wrjxs-data{
                margin-top: -7px;
                padding-right: 5px;
            }
            
        }
    }
  .xsgk-echart-content{
    width: 200px;
    height: 100px;
    position: absolute;
    left: 50px;
    top: 40px;
    .xsgk-echart-bg{
      position: absolute;
      width: 78px;
      height: 73px;
      background: url('@/assets/images/xsgk-echart-bg.png') no-repeat center;
      background-size: 100% 100%;
      left: 50%;
      top: 50%;
      transform:  translate(-50%,-47%);
    }
    .xsgk-echart-icon{
      position: absolute;
      width: 31px;
      height: 30px;
      background: url('@/assets/images/xsgk-echart-icon.png') no-repeat center;
      background-size: 100% 100%;
      left: 50%;
      top: 50%;
      transform:  translate(-50%,-50%);
    }
    .xsgk-echart{
      width: 100%;
      height: 100%;
    }
  }

}
</style>