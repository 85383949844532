<template>
    <div class="xsqdItem">
        <div class="xsqdItem-title flex_b_c">
            <p style="margin-top: 5px">10kV永桥D716线</p>
            <div class="status">
                <div class="status-error flex_c_c">
                    超期
                    <i class="flex_c_c">!</i>
                </div>
                <div class="status-normal flex_c_c">已发布</div>
            </div>
        </div>
        <div class="xsqdItem-list">

            <div class="xsqdItem-list-item flex_b_c">
                <p>
                    <img :src="icon['巡视类型']"/>
                    巡视类型 </p>
                <span>定期巡视</span>
            </div>
            <div class="xsqdItem-list-item flex_b_c">
                <p>
                    <img :src="icon['计划开始时间']"/>
                    计划开始时间 </p>
                <span>2023-11-06 08:00</span>
            </div>
            <div class="xsqdItem-list-item flex_b_c">
              <p>
                <img :src="icon['计划结束时间']"/>
                计划结束时间 </p>
              <span>2023-11-07 08:00</span>
            </div>
          <div class="xsqdItem-list-item flex_b_c">
            <p>
              <img :src="icon['巡视方式']"/>
              巡视方式 </p>
            <span>人工巡视</span>
          </div>
          <div class="xsqdItem-list-item flex_b_c">
            <p>
              <img :src="icon['巡视内容']"/>
              巡视内容 </p>
            <span>线路定期巡视</span>
          </div>
        </div>
        <div class="xsqdItem-btn flex_c_c">
            <p class="flex_c_c">定位</p>
        </div>
    </div>
</template>
<script>
export default{
    data() {
        return {
            icon:{
                "战线名称": require('@/assets/images/xsjhIcon/zxmc.png'),
                "巡视班组":require('@/assets/images/xsjhIcon/icon-1.png'),
                "巡视人员":require('@/assets/images/xsjhIcon/icon-2.png'),
                "实际巡视人员":require('@/assets/images/xsjhIcon/icon-2.png'),
                "计划状态":require('@/assets/images/xsjhIcon/icon-3.png'),
                "巡视类型":require('@/assets/images/xsjhIcon/icon-4.png'),
                "计划开始时间":require('@/assets/images/xsjhIcon/icon-5.png'),
                "计划结束时间":require('@/assets/images/xsjhIcon/icon-6.png'),
                "实际开始时间":require('@/assets/images/xsjhIcon/icon-5.png'),
                "实际结束时间":require('@/assets/images/xsjhIcon/icon-6.png'),
                "巡视方式":require('@/assets/images/xsjhIcon/icon-7.png'),
                "巡视内容":require('@/assets/images/xsjhIcon/icon-8.png'),
            },
        }
    },
}
</script>
<style lang="scss" scoped>
.xsqdItem{
    font-size: 14px;
    margin-bottom: 10px;
    width:  279px;
    height: 215px;
    background: url('@/assets/images/xsqd-list-bg.png') no-repeat center;
    background-size: 100% 100%;
    .xsqdItem-title{
        height: 31px;
        padding-top: 8px;
        padding-left: 40px;
        padding-right: 13px;
        color: #424242;
    }
    .status{
        display: flex;
        .status-error{
            position: relative;
            width:  42px;
            height: 20px;
            background: url('@/assets/images/xsqd-status-error.png') no-repeat center;
            background-size: 100% 100%;
            i{
                position: absolute;
                right: -2px;
                top: -2px;
                width: 8px;
                height: 8px;
                background-color: #E92929;
                border-radius: 100%;
                color: #FFFFFF;
                font-size: 6px;
            }
        }
        .status-normal{
            margin-left: 5px;
            width:  51px;
            height: 20px;
            background: url('@/assets/images/xsqd-status-normal.png') no-repeat center;
            background-size: 100% 100%;
        }
    }
    .xsqdItem-list{
        margin: 10px auto 0;
        width: 258px;
        height:  120px;
        overflow-x: hidden;
        overflow-y: scroll;
        .xsqdItem-list-item{
            margin-bottom: 3px;
            height: 20px;
        }
        p{
            width: 45%;
            text-align: left;
            color: #707070;
        }
        img{
            display: inline-block;
        }
        span{
            font-family: Microsoft YaHei, Microsoft YaHei;
            width: 55%;
            text-align: left;
            color: #000000;
        }
    }
    .xsqdItem-btn{
        margin: 2px auto 0;
        width:  128px;
        height: 35px;
        background: url('@/assets/images/xsqd-btn.png') no-repeat center;
        background-size: 100% 100%;
        color: #1DAAAA;
        p{
            margin-top: -2px;
            &::before{
                margin-right: 5px;
                content: "";
                width: 16px;
                height: 16px;
                background: url('@/assets/images/xsqd-btn-icon.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>