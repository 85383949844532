<template>
    <div class="xsjh">
        <div class="xsjh-title flex_b_c">
            <p>巡视计划</p>
        </div>
        <div class="xsjh-list">
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['战线名称']"/> 站线名称 </p>
                <span>10kv永桥D716线</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['巡视班组']"/>巡视班组 </p>
                <span>江北供电所配电班</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['巡视人员']"/>巡视人员 </p>
                <span>王许可</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['实际巡视人员']"/>实际巡视人员 </p>
                <span>王许可</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['计划状态']"/>计划状态 </p>
                <span>已发布</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['巡视类型']"/>巡视类型 </p>
                <span>定期巡视</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['计划开始时间']"/>计划开始时间 </p>
                <span>2023-11-06 08:00</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['计划结束时间']"/>计划结束时间 </p>
                <span>2023-11-06 08:00</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['实际开始时间']"/>实际开始时间 </p>
                <span>2023-11-06 08:00</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['实际结束时间']"/>实际结束时间 </p>
                <span>2023-11-06 08:00</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['巡视方式']"/>巡视方式 </p>
                <span>人工巡视</span>
            </div>
            <div class="xsjh-list-item flex_b_c">
                <p><img :src="icon['巡视内容']"/>巡视内容 </p>
                <span>10kv永桥D716线定期巡视</span>
            </div>
        </div>
        <div class="xsjh-btn flex_c_c">
            <p class="flex_c_c">定位</p>
        </div>
    </div>
</template>
<script>
export default{
    data() {
        return {
            icon:{
                "战线名称": require('@/assets/images/xsjhIcon/zxmc.png'),
                "巡视班组":require('@/assets/images/xsjhIcon/icon-1.png'),
                "巡视人员":require('@/assets/images/xsjhIcon/icon-2.png'),
                "实际巡视人员":require('@/assets/images/xsjhIcon/icon-2.png'),
                "计划状态":require('@/assets/images/xsjhIcon/icon-3.png'),
                "巡视类型":require('@/assets/images/xsjhIcon/icon-4.png'),
                "计划开始时间":require('@/assets/images/xsjhIcon/icon-5.png'),
                "计划结束时间":require('@/assets/images/xsjhIcon/icon-6.png'),
                "实际开始时间":require('@/assets/images/xsjhIcon/icon-5.png'),
                "实际结束时间":require('@/assets/images/xsjhIcon/icon-6.png'),
                "巡视方式":require('@/assets/images/xsjhIcon/icon-7.png'),
                "巡视内容":require('@/assets/images/xsjhIcon/icon-8.png'),
            },
        }
    },
}
</script>
<style lang="scss" scoped>
.xsjh{
    font-size: 14px;
    margin-bottom: 10px;
    width:  322px;
    height: 420px;
    background: url('@/assets/images/xsjh-bg.png') no-repeat center;
    background-size: 100% 100%;
    .xsjh-title{
        position: relative;
        width:  252px;
        height: 56px;
        padding-top: 15px;
        padding-left: 37px;
        color: #1DAAAA;
        p{
            margin-left: 5px;
            padding-left: 5px;
        }
        &::after{
            position: absolute;
            bottom: 0;
            content: "";
            width:  252px;
            height: 3px;
            background: url('@/assets/images/xsjh-title-line.png') no-repeat center;
            background-size: 100% 100%;
        }
    }
    
    .xsjh-list{
        margin: 10px auto 0;
        width: 252px;
        height:  290px;
        overflow-x: hidden;
        overflow-y: scroll;
        .xsjh-list-item{
            margin-bottom: 3px;
            height: 20px;
            img{
                margin-right: 3px;
                display: inline-block;
            }
        }
        p{
            width: 42%;
            text-align: left;
            color: #707070;
            white-space: nowrap;
        }
        span{
            white-space: nowrap;
            font-family: Microsoft YaHei, Microsoft YaHei;
            width: 58%;
            text-align: right;
            color: #000000;
        }
    }
    .xsjh-btn{
        margin: 2px auto 0;
        width:  128px;
        height: 35px;
        background: url('@/assets/images/xsqd-btn.png') no-repeat center;
        background-size: 100% 100%;
        color: #1DAAAA;
        p{
            margin-top: -2px;
            &::before{
                margin-right: 5px;
                content: "";
                width: 16px;
                height: 16px;
                background: url('@/assets/images/xsqd-btn-icon.png') no-repeat;
                background-size: 100% 100%;
            }
        }
    }
}
</style>