<template>
    <div class="xszyfx-znzy-item">
        <div class="xszyfx-znzy-item-title">线路覆盖图</div>
        <div class="xszyfx-znzy-chart-bg">
          <div class="xszyfx-znzy-echart" ref="xszyfxZnzyChart"></div>
          <div class="xszyfx-znzy-echart-value">95<span>%</span></div>
          <div :class="getIconClass"></div>
        </div>
        <div class="xszyfx-znzy-list">
            <div class="xszyfx-znzy-list-item flex_b_c">
                <p><i></i>线路数</p>
                <p><span>100</span>条</p>
            </div>
            <div class="xszyfx-znzy-list-item flex_b_c">
                <p><i></i>巡视线路</p>
                <p><span>5</span>条</p>
            </div>
            <div class="xszyfx-znzy-list-item flex_b_c">
                <p><i></i>人工</p>
                <p><span>10</span>条</p>
            </div>
            <div class="xszyfx-znzy-list-item flex_b_c">
                <p><i></i>人工</p>
                <p><span>100</span>条</p>
            </div>
            <div class="xszyfx-znzy-list-item flex_b_c">
                <p><i></i>人工</p>
                <p><span>100</span>条</p>
            </div>
        </div>

    </div>
</template>
<script>
export default {
  props:{
    'type':{
      type: Number,
      required:true,
      default:1,

    },'Job':Object
  },
  data() {
    return {
      pieData:[{
        name: "政务信息",
        value: 3500,
      }, {
        name: "政法信息",
        value: 1500,
      }, {
        name: "检察信息",
        value: 150,
      }, {
        name: "检察信息",
        value: 150,
      }],
      options: {
        "animation": true,
        series: [
          {
            "type": "pie",
            "center": ["50%", "50%"],
            "radius": ["40%", "48%"],
            "color": ["#67C23A", "#F56C6C", "#2DACAC", "#50CBCB"],
            "startAngle": 135,
            "labelLine": {
              "normal": {
                "length": 25
              }
            },
            "label": {
              "normal": {
                show: false
              }
            },
            "data":[],
            emphasis: {
              itemStyle: {

              }
            },
          }
        ]
      }
    }
  },
  computed:{
    getIconClass(){
      return "xszyfx-znzy-echart-icon"+this.type;
    }
  },
  mounted() {
    setTimeout(() => {
      this.loadChart([40,30,30,30])
    }, 100);
  },
  methods:{
    loadChart(value) {
      let {xszyfxZnzyChart} = this.$refs
      let chart = this.$echarts.init(xszyfxZnzyChart)
      let option = {
        "animation": true,
        "series": [{
          "type": "pie",
          "center": ["50%", "50%"],
          "radius": ["60%", "68%"],
          itemStyle: {
            normal: {
              shadowBlur: 10,
              shadowColor: "#aec3cb"
            }
          },
          "color": ["#EE6969","#67C23A", "#0085C9", "#2FB5B5", "#ff924f", "#00FFA8", "#9F17FF", "#FFE400", "#FE2C8A"],
          "startAngle": 125,
          "label": {
            "normal": {
              show: false
            }
          },
          "data": [{
            name: "人工巡视",
            value: value[0],

          }, {
            name: "无人机巡视",
            value: value[1],
          }, {
            name: "机器人巡视",
            value: value[2],
          }, {
            name: "机器人巡视1",
            value: value[3],
          }]

        }]
      };
      chart.setOption(option)
    },
  }
}
</script>
<style lang="scss" scoped>
.xszyfx-znzy-item{
    position: relative;
    width: 81px;
    &::before{
        content: "";
        margin-bottom: 6px;
        display: block;
        width: 81px;
        height: 2px;
        background: url('@/assets/images/xszyfx-znzy-line.png') no-repeat center;
        background-size:  100% 100%;
    }
    &::after{
        position: absolute;
        content: "";
        top: 0;
        right: -8px;
        width: 2px;
        height: 208px;
        // border: 1px solid #D4DBE2;
        background: linear-gradient(to bottom,transparent 0%,transparent 50%,#D4DBE2 50%,#D4DBE2 100%);
        background-size: 1px 8px;
        background-repeat: repeat-y;
    }
    .xszyfx-znzy-item-title{
        margin-bottom: 11px;
        font-size: 10px;
        color: #000000;
        text-align: center;
        width: 100%;
    }
    .xszyfx-znzy-chart-bg{
        margin: 0 auto;
        width: 75px;
        height: 73px;
        background: url('@/assets/images/xszyfx-znzy-chart-bg.png') no-repeat center;
        background-size:  100% 100%;
      position: relative;
    }
    .xszyfx-znzy-echart{
      position: absolute;
      left: -3px;
      top: -3px;
      width: 80px;
      height: 80px;

    }
  .xszyfx-znzy-echart-icon1{
    position: absolute;
    width: 22px;
    height: 20px;
    left: 50%;
    top:50%;
    transform: translate(-50%,-20%);
    background: url('@/assets/images/xszyfx-fxl-icon.png') no-repeat center;
  }
  .xszyfx-znzy-echart-value{
    position: absolute;
    top:20%;
    width: 80px;
    text-align: center;
    height: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #67C23A;
    span{
      font-size: 8px;
      color: #bdbdbd;
    }
  }
  .xszyfx-znzy-echart-icon2{
    position: absolute;
    width: 22px;
    height: 20px;
    left: 50%;
    top:50%;
    transform: translate(-50%,-20%);
    background: url('@/assets/images/xszyfx-fxl-icon.png') no-repeat center;
  }
  .xszyfx-znzy-echart-icon3{
    position: absolute;
    width: 22px;
    height: 20px;
    left: 50%;
    top:50%;
    transform: translate(-50%,-20%);
    background: url('@/assets/images/xszyfx-fxl-icon.png') no-repeat center;
  }
    .xszyfx-znzy-list-item{
        margin-top: 10px;
        font-size: 8px;
        margin-bottom: 10px;
        i{
            display: inline-block;
            margin-right: 3px;
            width: 5px;
            height: 5px;
            background: url('@/assets/images/xszyfx-znzy-list.png') no-repeat center;
            background-size:  100% 100%;
        }
        span{
            font-size: 10px;
            color: #2FB5B5;
            margin-right: 4px;
        }
    }
}
</style>