<template>
    <div class="xsjd g-glossy">

        <div class="title ">
            <p>本月巡视进度(实时)</p>
            <div class="tab flex_c_c">
                <div class="tab-item flex_c_c" :class="{'tab-item-s':tabActive===1}" @click="showTabActive(1)">巡视清单</div>
                <div class="tab-item flex_c_c" :class="{'tab-item-s':tabActive===2}" @click="showTabActive(2)">单位对比</div>
            </div>
        </div>
        <div class="xsjd-content">
            <div class="xsjd-echart" ref="xsjdChart"></div>
        </div>
    </div>
</template>
<script>
import echarts from "echarts";

export default {
    data() {
        return {
            options: {
                xAxis: {
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#999'
                    }

                },
                yAxis: {
                    type: 'category',
                    data: ['待执行', '执行中', '已执行', '逾期未完成']
                },
                series: [
                    {
                        type: 'bar',
                        showBackground: true,
                        itemStyle: {

                        },
                        emphasis: {
                            itemStyle: {

                            }
                        },
                    }
                ]
            },
          tabActive:1,
          tabDataList:{
              1:[60,80,60,90],
              2:[40,76,40,40],
          }
        }
    },
    mounted() {
        setTimeout(() => {
            this.loadChart([40,60,70,90])
        }, 100);
    },
    methods:{
        loadChart(value1) {
            // if (options) {
            //     this.options = Object.assign(this.options, options)
            // }
            let {xsjdChart} = this.$refs
            let chart = this.$echarts.init(xsjdChart)
            // let data = [220, 182, 191, 234];
            // let dataAxis = ['待执行', '执行中', '已执行', '逾期未完成'];
          let data = [
            {value: value1[0],name: '待执行'},
            {value: value1[1],name: '执行中'},
            {value: value1[2],name: '已执行'},
            {value: value1[3],name: '逾期\n未完成'}
          ];

          let getArrByKey = (data, k) => {
            let key = k || 'value';
            let res = [];
            if (data) {
              data.forEach(function (t) {
                res.push(t[key]);
              });
            }
            return res;
          };
          let getSymbolData = (data) => {
            let arr = [];
            for (let i = 0; i < data.length; i++) {
              arr.push({
                value: data[i].value+"/100",
                symbolPosition: 'end',
              });
            }
            return arr;
          };
          let option = {
            backgroundColor:"",
            grid: {
              top:'10%',
              bottom:'2%',
              right:'2%',
              left:'15%',
              containLabel: true,
            },
            xAxis: {
              // position: 'bottom',
              type: 'value',
              position: 'bottom',
              interval:20, // 步长
              min:0, // 起始
              max:100, // 终止
              splitLine: {
                lineStyle: {
                  type: 'dashed'
                }
              }
            },

            series: [
              {
                type: 'bar',
                showBackground: true,
                barMinWidth:'5',
                backgroundStyle: {
                  opacity: 1,
                  color: '#E5E9EE',
                  borderRadius: 5,

                },
                yAxisIndex: 0,
                data: data,
                barWidth: 7,
                // align: left,
                // stack: "2",
                itemStyle: {
                  normal: {

                    // background: linear-gradient(180deg, #1DAAAA 0%, #EBFFFA 26%, #92DBCF 61%, #009595 100%);
                    opacity: 0.57,
                    //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                    color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "#1DAAAA",
                      },
                      {
                        offset: 0.25,
                        color: "#EBFFFA",
                      },
                      {
                        offset: 0.6,
                        color: "#92DBCF",
                      },
                      {
                        offset: 1,
                        color:  "#009595",
                      },
                    ]),
                  },
                },
              },
              {
                type: 'pictorialBar',
                symbol: 'circle',
                symbolSize: [7, 7],
                symbolOffset: [3, 0],
                zlevel: 13,
                barMinHeight:1,
                symbolPosition: 'end',
                itemStyle: {
                  normal: {
                    color:new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: '#1DAAAA'
                    }, {
                      offset: 1,
                      color: 'rgba(15,85,85,0)'
                    }]),
                  }
                },
                data: data,
              },
            ],
            yAxis: [
              {
                show: true,
                inverse: true,
                data: getArrByKey(data, 'name'),
                axisLine: {
                  show: false,
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  interval: 0,
                  inside: true,
                  color: '#707070',
                  margin:-30,
                  padding: [0, 0, -5, 0],
                  align: 'left',
                  verticalAlign:'bottom',
                  formatter: function (value, index) {
                    return '{title|' + value + '}';
                  },
                  rich: {
                    title: {
                      width: 50,
                      fontSize: 8,
                    },
                  },
                },
              },
              {
                triggerEvent: false,
                z: 10,
                show: true,
                inverse: true,
                data: getArrByKey(data, 'name'),
                axisLine: {
                  show: false,
                },
                splitLine: {
                  show: false,
                },
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  margin: 0,
                  interval: 0,
                  inside: false,
                  padding: [0, 0, -5, 0],
                  color: '#2EB1B1',
                  fontSize: 11,

                  align: 'right',
                  verticalAlign: 'bottom',
                  formatter: function (value, index) {
                    return data[index].value+"/100";
                  },
                },
              },
            ],
          };
            chart.setOption(option)
        },

      showTabActive(value){
        this.tabActive = value;
        this.loadChart(this.tabDataList[value]);
      }
    }


}
</script>
<style lang="scss" scoped>
.xsjd{
    font-size: 14px;
    margin-bottom: 10px;
    width: 288px;
    height: 178px;
    background: rgba(255,255,255,0.1);
    border-radius: 5px 5px 8px 8px;
    box-shadow: 0 0 3px 3px rgba(33, 33, 33, 0.1);
    &.g-glossy::before{
      width: 288px;
      height: 178px;
    }
    .title{
        position: relative;
        padding:  0 5px 0 20px;
        height: 38px;
        font-size: 14px;
        color: #424242;
        display: flex;
        align-items: center;
        justify-content: space-between;
       background: linear-gradient(180deg, rgba(83,213,191,0.25) 0%, rgba(0,132,201,0) 100%);
      border-radius: 5px 5px 0 0;
        &::before{
            position: absolute;
            left: 10px;
            content: "";
            width: 4px;
            height: 16px;
            background: url('@/assets/images/comp-title-before.png') no-repeat center;
            background-size: 100% 100%;
        }
        &::after{
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            content: "";
            width: 269px;
            height: 1px;
            background: url('@/assets/images/comp-title-tab-line.png') no-repeat center;
            background-size: 100% 100%;
        }
        .tab{
            font-size: 12px;
            color: #707070;
            width: 136px;
            height: 28px;
            background: url('@/assets/images/comp-title-tab-bg.png') no-repeat center;
            background-size: 100% 100%;
            .tab-item{
                width: 48%;
                margin-top: -3px;
                height: 21px;
            }
            .tab-item-s{
                color: #fff;
                background: url('@/assets/images/comp-title-tab-s.png') no-repeat center;
                background-size: 100% 100%;
            }
        }
    }
    .xsjd-content{
        width: 300px;
        height: 130px;
    }
    .xsjd-echart{
        width: 100%;
        height: 100%;
    }
}
</style>