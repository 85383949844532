<template>
    <div class="titleComp">
<!--        <div class="logo flex_c_c">logo</div>-->


<!--        <div class="title">数字化配电网</div>-->
        <div class="zyszh flex_c_c">作业数字化</div>

        <div class="tab flex_c_c">
            <div class="tab-item flex_c_c">
                <i class="bzhjs"></i>建设化标准</div>
            <div class="tab-item flex_c_c tab-item-s">
                <i class="jyhyw"></i>精益化运维</div>
            <div class="tab-item flex_c_c">
                <i class="znhgk"></i>智能化管控</div>
            <div class="tab-item flex_c_c">
                <i class="ywhc"></i>源网荷储</div>
        </div>
        <div class="search"></div>
        <div class="head"></div>
        <div class="func-btn flex_c_c">
            <div class="btn flex_c_c icon-notice"></div>
            <div class="btn flex_c_c icon-setting"></div>
            <div class="btn flex_c_c icon-exit"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'TitleComp',
  components: {
    
  }
}
</script>
<style lang="scss" scoped>
.titleComp{
    width: 1920px;
  height: 64px;
    //background: linear-gradient(87deg, #9DEBEB 0%, #F0F9F9 41%, #E7F3F5 100%);
    background: url("@/assets/images/comp-title-bg.png") top left;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: flex;
    align-items:  center;
    .logo{
        width: 64px;
        height: 64px;
        background: #1DAAAA;
        opacity: 1;
        margin-right: 26px;
    }
    .title{
        font-size: 28px;
        font-family: MF BanHei (Noncommercial), MF BanHei (Noncommercial);
        color: #1DAAAA;
    }
    .zyszh{
        margin-left: 300px;
        font-size: 14px;
        color: #1DAAAA;
        width: 132px;
        height: 33px;
        background: url("@/assets/images/zyszh.png") no-repeat center;
        background-size: 100% 100%;
    }
    .tab{
        margin-left: 150px;
        width: 805px;
        height: 46px;
        background: url("@/assets/images/title-tab.png") no-repeat center;
        background-size: 100% 100%; 
        .tab-item{
            width: 25%;
            height: 29px;
            color: #707070;
            font-size: 16px;
            &.tab-item-s{
                color: #1DAAAA;
                background: url("@/assets/images/title-tab-s.png") no-repeat center;
                background-size: 100% 100%; 
            }
            i.bzhjs{
                margin-right: 9px;
                width: 14px;
                height: 17px;
                background: url('@/assets/images/home-bzhjs.png') no-repeat center;
                background-size: 100% 100%;
            }
            i.jyhyw{
                margin-right: 7px;
                width: 18px;
                height: 17px;
                background: url('@/assets/images/home-jyhyw.png') no-repeat center;
                background-size: 100% 100%;
            }
            i.znhgk{
                margin-right: 10px;
                width: 17px;
                height: 18px;
                background: url('@/assets/images/home-znhgk.png') no-repeat center;
                background-size: 100% 100%;
            }
            i.ywhc{
                margin-right: 8px;
                width: 20px;
                height: 19px;
                background: url('@/assets/images/home-ywhc.png') no-repeat center;
                background-size: 100% 100%;
            }
            
            
        }
    }
    .search{
        margin-left: 24px;
        margin-right: 28px;
        width: 248px;
        height: 45px;
        background: url("@/assets/images/search.png") no-repeat center;
        background-size: 100% 100%;
    }
    .head{
        margin-right: 54px;
        width: 40px;
        height: 40px;
        background: url("@/assets/images/head.png") no-repeat center;
        background-size: 100% 100%;
    }
    .func-btn{
        .btn{
            width: 48px;
            height: 48px;
            background: url("@/assets/images/title-btn.png") no-repeat center;
            background-size: 100% 100%;
            &::before{
                height: 20px;
                content: "";
            }
            &.icon-exit{
                &::before{
                    width: 20px;
                    background: url("@/assets/images/title-exit.png") no-repeat center;
                    background-size: 100% 100%;
                }
            }
            &.icon-notice{
                &::before{
                    width: 20px;
                    background: url("@/assets/images/title-notice.png") no-repeat center;
                    background-size: 100% 100%;
                }
            }
            &.icon-setting{
                &::before{
                    width: 20px;
                    background: url("@/assets/images/title-setting.png") no-repeat center;
                    background-size: 100% 100%;
                }
            }
        }
    }
}
</style>